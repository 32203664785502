import React from "react";

export const Card = ({ img, text, buttonText }) => {
  return (
    <div className="flex flex-col overflow-hidden bg-white border rounded-md shadow-md">
      <img className="bg-red-300 h-72" alt="card" src={img} />
      <div className="p-6">
        <h3 className="mb-6 text-2xl font-semibold">{text}</h3>
        {buttonText && (
          <button className="w-full px-4 py-2 text-2xl text-white bg-blue-400 rounded-lg">
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};
