import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export const ImageCardRow = ({ img, text, body, rating, buttonText }) => {
  return (
    <div className="flex flex-col items-center p-2 mb-10 bg-white border rounded-md shadow-md md:items-start md:flex-row md:p-0">
      <img
        src={img}
        alt="card-row"
        className="w-48 h-48 bg-orange-300 rounded-md"
      />
      <div className="flex flex-col items-center justify-center flex-1 md:items-start md:px-10">
        <h3 className="mb-4 text-2xl font-semibold md:mb-6 md:text-4xl">{text}</h3>
        {body && <p className="mb-4 text-base md:text-xl">{body}</p>}
        {rating && (
          <div className="flex flex-col items-center md:flex-row">
            <span className="mr-4 text-xl">{`${rating} Rating`}</span>
           <div>
           {Array.from(Array(rating), () => (
              <FontAwesomeIcon icon={faStar} className="mx-1 text-yellow-400" />
            ))}
             </div>
          </div>
        )}
        {buttonText && <div className="flex justify-center md:justify-start "><button className="self-start px-10 py-2 text-2xl text-white bg-blue-400 rounded-lg">{buttonText}</button></div>}
      </div>
    </div>
  );
};
