import React from "react";
import { ImageCardRow } from "../../components";
import { AboutBanner } from "./AboutBanner";
import Image02 from '../../assets/image-02.png'
import Image03 from '../../assets/image-03.png'
import Image04 from '../../assets/image-04.png'

export const About = () => {
  const aboutMeData = [
    {
      img: Image02,
      text: "Start creating a sustainable, curious and mindful living. Let's work to embody more of the intersection of social anction, green/sustainable living, and midful lifestyle design.",
    },
    {
      img: Image03,
      text: "Do you dream of living a creative and adventurous life? Let's start rejecting the status quo & go rebelling against mediocrity."
    },
    {
      img: Image04,
      text: "Lifestyle Without Limits. Let's live as a community where people can collaborate on fun ways to change the world around them."
    }
  ];
  
  return (
    <>
      <AboutBanner />
      <div className="p-4 md:py-20 md:px-4">
        {
          aboutMeData.map((aboutMe, index) => <ImageCardRow key={index} body={aboutMe.text} img={aboutMe.img} />)
        }
      </div>
    </>
  );
};
