
export const AboutBanner = () => {
  return (
    <div className="relative flex flex-col items-center justify-center p-2 bg-no-repeat bg-cover h-72 md:px-56 bg-about-us-banner md:h-96">
      <div className="absolute z-10 w-full h-full bg-gray-500 bg-opacity-40"/>
      <h2 className="z-10 mb-2 text-4xl font-semibold text-yellow-100 md:text-6xl md:mb-10">ABOUT ME</h2>
      <p className="z-10 text-sm text-center text-white bg-opacity-50 rounded-md md:text-2xl">
        I'm Jazelle, a life coach that enjoys and helps people achieve their
        best life. I'm here to guide you with your overall wellness. As your
        life coach, I'm here to help you balance out the rational and emotional
        decisions you make. To have a clear mind and have a peaceful well-being,
        that's what we focus on. A balanced life with mindfulness of yourself
        and surroundings.
      </p>
    </div>
  );
};
