import { ImageCardRow } from "../../components";
import Review01 from "../../assets/review-1.jpg";
import Review02 from "../../assets/review-2.jpg";
import Review03 from "../../assets/review-3.jpg";

export const Review = () => {
  const data = [
    {
      img: Review01,
      text: "Thad the busiest schedules, overloads and stress. Jazelle was a big help for me managing my stree, not just stress but with my life.",
      rating: 5,
    },
    {
      img: Review02,
      text: "The City Life is easy? It never was, transitioning from a small town to a large city took a big toll on me. Until Jazelle came in and helped me.",
      rating: 5,
    },
    {
      img: Review03,
      text: "Parenting Twins was a hectic schedule along side striving for your passion. To give the best for my twins and myself, I called Jazelle. Here I am, with this two well grown children and an upcoming exhibit.",
      rating: 5,
    },
  ];
  return (
    <div className="p-8 md:px-32 md:py-20">
      <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
        REVIEWS
      </h2>
      <div>
        {data.map((review) => (
          <ImageCardRow
            rating={review.rating}
            body={review.text}
            img={review.img}
          />
        ))}
      </div>
    </div>
  );
};
