import React from "react";

export const Banner = () => {
  return (
    <div className="flex p-1 bg-no-repeat bg-contain md:p-8 md:h-128 bg-home-banner md:bg-cover">
      <div className="flex flex-col flex-1 ">
        <div className="flex flex-col justify-center flex-1">
          <h2 className="text-lg leading-10 text-white md:mb-4 md:text-4xl md:w-96">
            LIVE THE WAY
            <br />
            <span className="text-2xl font-semibold text-yellow-100 md:text-6xl">YOU WANT</span>
          </h2>
          <p className="w-48 text-xs text-white md:text-2xl">IM JAZELLE, YOUR LIFE AND CAREER COACH</p>
        </div>
        <p className="text-blue-300 text-md md:text-2xl">
          LIVE FREE, WORK FREE, BE FREE
        </p>
      </div>
    </div>
  );
};
