import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faLocation } from "@fortawesome/free-solid-svg-icons";

export const Contact = () => {
  return (
    <div className="p-8 md:px-32 md:py-20">
      <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
        EMAIL ME
      </h2>
      <div className="flex flex-col mb-10 md:mb-20 md:mx-72">
        <input
          className="px-8 py-4 mb-4 border rounded-md shadow-md"
          placeholder="Full Name"
        />
        <input
          className="px-8 py-4 mb-4 border rounded-md shadow-md"
          placeholder="E-Mail"
        />
        <textarea
          className="px-8 py-4 mb-4 border rounded-md shadow-md"
          placeholder="Your Concern"
          rows={6}
        />
        <button className="px-8 py-4 text-xl text-white bg-blue-400 rounded-md shadow-md">
          SEND NOW
        </button>
      </div>
      <div className="flex flex-col items-center">
        <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
          CONTACTS
        </h2>
        <ul className="text-xl">
          <li className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mr-4"/>elitejcoaching@gmail.com</li>
          <li className="mb-2"><FontAwesomeIcon icon={faPhone} className="mr-4"/>903-986-4471</li>
          <li className="mb-2"><FontAwesomeIcon icon={faLocation} className="mr-4"/>Kilgore, Texas (TX), 75662</li>
        </ul>
      </div>
    </div>
  );
};
