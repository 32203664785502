import { Banner } from "./HomeBanner";
export const Home = () => {
  return (
    <div>
      <Banner />
      <div className="px-4 py-4 md:px-32 md:py-20">
        <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
          BE THE BETTER YOU
        </h2>
        <p className="mb-6 text-md md:text-lg">
          As I begin shaping you to achieve the better you, you may be thinking
          about or have even already committed to have a new goal for yourself.
          If this is the case, I can help consider what will it take to meet
          that goal. In the last few months, there has been a lot of happenings
          and interest in the articles I've written that offer this kind of
          guidance. So, to help you connect to each piece of advice into one
          overall plan, I'm here to give you a short explanation of where they
          sit into your journey toward change.
        </p>
        <p className="mb-6 text-md md:text-lg">
          Anytime you decide to change, you will experience some uncertainties.
          Some part of you wants to change while another part of you leans
          towards continuining the way you've always been. The more evenly these
          are balanced, the more difficulty you will have with starting to make
          changes or persisting in your efforts. Be aware that sometimes the
          pull to stay the same is subconscious and is more emotional than
          rational.
        </p>
        <p className="mb-6 text-md md:text-lg">
          Once you are more committed to achieving your goal, it's essential to
          tap your inner motivation. By knowing more about your inner
          experiences - such as your thoughts and feelings, you can choose to
          focus on those aspects that heighten your intrinsic desire to be
          different. This article identifies five domains of awareness and
          offers ways for you to access and use them to increase your motivation
          to attain your goals.
        </p>
        <p className="mb-6 text-md md:text-lg">
          Setbacks can be discouraging - even demoralizing - for anyone. To keep
          moving toward your goal, it is essential that you have compassion for
          your struggles and encourage yourself, just as you would do so for a
          friend. This article defines what self-compassion is, what it is not,
          and how it can be just what you need to feel good about yourself even
          as you persevere through the inevitable problems that arise in trying
          to achieve your goals.
        </p>
        <p className="mb-6 text-md md:text-xl">
          Now that you have prepared yourself in mind and spirit - a bit like
          preparing the soild of a garden for a fruitful season - you are ready
          to take the steps toward change. This article provides you with a way
          to structure and follow through with an effective plan of action. I
          wish you all a healthy, happy year filled with meaning and fulfilling
          personal development.
        </p>
      </div>
    </div>
  );
};
