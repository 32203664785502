import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faPinterest, faTwitter, faGooglePlus } from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <div className="border-t min-h-32">
      <div className="flex flex-col items-center justify-center max-w-screen-xl py-4 mx-auto md:py-10 md:flex-row">
        <div className="flex-1">
          <h2 className="text-4xl font-bold text-center text-blue-400 md:text-6xl md:text-left">
            LETS WORK TOGETHER
          </h2>
          <p className="mb-2 text-xl text-center md:text-2xl md:text-left">
            SIGNUP NOW AND START CHANGING <br /> YOURSELF FOR THE BETTER!
          </p>
        </div>
        <div className="flex flex-col items-center w-96">
          <button className="w-full px-4 py-2 mb-4 text-2xl font-bold text-white bg-blue-400 border rounded-lg md:py-4 md:px-8 md:text-6xl">
            SIGN UP
          </button>
          <span className="mb-4 text-xl font-semibold md:text-2xl">
            YOU CAN ALSO REACH ME HERE
          </span>
          <div className="flex text-blue-400">
            <div className="w-12 h-12 mx-1 ">
              <FontAwesomeIcon icon={faFacebook} className="w-full h-full" />
            </div>
            <div className="w-12 h-12 mx-1 rounded-md">
              <FontAwesomeIcon icon={faInstagram} className="w-full h-full" />
            </div>
            <div className="w-12 h-12 mx-1 rounded-md">
              <FontAwesomeIcon icon={faPinterest} className="w-full h-full" />
            </div>
            <div className="w-12 h-12 mx-1 rounded-md">
              <FontAwesomeIcon icon={faTwitter} className="w-full h-full" />
            </div>
            <div className="w-12 h-12 mx-1 rounded-md">
              <FontAwesomeIcon icon={faGooglePlus} className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
