import React from "react";

export const Coach = () => {
  return (
    <div className="flex flex-col items-center flex-grow p-8 md:px-56 md:py-20">
      <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
        ALL IN ONE LIFE COACHING
      </h2>
      <p className="mb-6 text-lg">
        The coaching consist of all the foundations, calls, check-up and
        anything you need for getting your life better. There's a lot of
        consideration, yet i'll provide it all to you.
      </p>
      <h3 className="mb-4 text-2xl font-semibold text-center text-blue-400 md:mb-10 md:text-4xl">
        What can I do for you?
      </h3>

      <div className="p-10 mb-10 bg-white border rounded-md shadow-md">
        <ul className="list-disc">
          <li>
            Better work/life balance - Elimination of long-held fears and
            anxieties
          </li>
          <li>Enhanced creativity - Greater financial security</li>
          <li>Improved communication skills - More satisfying work life</li>
          <li>
            Stronger relationships with friends and farmily What's the
            difference of a Life Coach and a Therapist?
          </li>
        </ul>
      </div>

      <h3 className="mb-4 text-2xl font-semibold text-center text-blue-400 md:mb-10 md:text-4xl">
        What's the difference of a Life Coach and a Therapist?
      </h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="p-10 bg-white border rounded-md shadow-md">
          <h4 className="mb-4 text-xl font-semibold text-center text-blue-400 md:text-2xl">
            Therapists
          </h4>
          <ul className="list-disc">
            <li>
              Can treat mental health conditions · Have a degree and are
              licensed in a related field
            </li>
            <li>Adhere to ethical codes</li>
          </ul>
        </div>
        <div className="p-10 bg-white border rounded-md shadow-md">
          <h4 className="mb-4 text-xl font-semibold text-center text-blue-400 md:text-2xl">
            Life Coach
          </h4>
          <ul className="list-disc">
            <li>Cannot treat mental health conditions</li>
            <li>Do not need any formal qualifications or academic training</li>
            <li>Are not required to follow health privacy laws</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
