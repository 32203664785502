import { Outlet, Route, Routes } from "react-router-dom";
import { Body, Footer, Navbar } from "./components";
import { Home, About, Review, Coach, Blog, Contact } from "./pages";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="flex flex-col min-h-screen ">
            <Navbar />
            <Body>
              <Outlet />
            </Body>
            <Footer />
          </div>
        }
      >
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/review" element={<Review />} />
        <Route path="/coach" element={<Coach />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
  );
}

export default App;
