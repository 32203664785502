import blog1 from '../../assets/blog-1.jpg'
import blog2 from '../../assets/blog-2.png'
import blog3 from '../../assets/blog-3.jpg'
import blog4 from '../../assets/blog-4.jpg'
import blog5 from '../../assets/blog-5.jpg'
import blog6 from '../../assets/blog-6.jpg'
import preview from '../../assets/preview.jpg'
import { Card, ImageCardRow } from "../../components";

export const Blog = () => {
  const data = [
    {
      img: blog1,
      text: "A Cup of Tea",
    },
    {
      img: blog2,
      text: "Veggie Freshie",
    },
    {
      img: blog3,
      text: "Nature Venture",
    },
    {
      img: blog4,
      text: "Photo Ops",
    },
    {
      img: blog5,
      text: "City Picnic",
    },
    {
      img: blog6,
      text: "Hike and Alive",
    },
  ];
  return (
    <div className="p-8 md:px-32 md:py-20">
      <h2 className="mb-4 text-4xl font-semibold text-center text-blue-400 md:mb-10 md:text-6xl">
        BLOGS
      </h2>
      <ImageCardRow text="You Got a Friend in Me" body="You aren't alone on your journey, I'm along side you get to the best of yourself." img={preview} buttonText="Read more"/>
      <h3 className="mb-10 text-4xl font-semibold text-center text-blue-400">
        More Articles
      </h3>
      <div className="grid grid-cols-1 gap-20 md:grid-cols-3">
        {data.map((blog)=><Card text={blog.text} img={blog.img} buttonText="Read more"/>)}
      </div>
    </div>
  );
};
