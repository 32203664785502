import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <div className="flex items-center justify-between p-4 border-b md:p-8">
        <div>
          <h1 className="text-xl font-semibold text-blue-300">
            EliteJLifeCoaching
          </h1>
        </div>
        <ul className="items-center hidden text-xl md:flex">
          <li className="mx-4">
            <Link to="/">Home</Link>
          </li>
          <li className="mx-4">
            <Link to="/about">About</Link>
          </li>
          <li className="mx-4">
            <Link to="/review">Review</Link>
          </li>
          <li className="mx-4">
            <Link to="/coach">Coach</Link>
          </li>
          <li className="mx-4">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="mx-4">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <FontAwesomeIcon
          icon={faBars}
          className="h-6 text-black md:hidden"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div
        className={`${
          open ? `max-h-[500px]` : "max-h-0"
        } transition-all delay-350 duration-1000 ease-in-out md:hidden`}
      >
        <ul className="text-xl ">
          <li className="m-1">
            <Link to="/">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                Home
              </button>
            </Link>
          </li>
          <li className="m-1">
            <Link to="/about">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                About
              </button>
            </Link>
          </li>
          <li className="m-1">
            <Link to="/review">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                Review
              </button>
            </Link>
          </li>
          <li className="m-1">
            <Link to="/coach">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                Coach
              </button>
            </Link>
          </li>
          <li className="m-1">
            <Link to="/blog">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                Blog
              </button>
            </Link>
          </li>
          <li className="m-1">
            <Link to="/contact">
              <button className="w-full px-2 py-1 border border-blue-300 rounded-sm">
                Contact
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
